<template>
    <button v-if="editingAllowed" class="edit-button" @click="showEditor(type, context)">
        <svgicon color="white" height="15" icon="pencil" width="15" />
        <span v-if="textLabel">{{ textLabel }}</span>
    </button>
</template>

<script>
export default {
    props: {
        icon: { type: String, default: null },
        type: { type: String, default: null },
        text: { type: String, default: null },

        context: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    computed: {
        textLabel() {
            return this.text || 'Edit';
        },
    },
};
</script>

<style lang="scss" scoped>
.edit-button {
    box-shadow: 0 3px 8px 0px rgba(0, 0, 0, 0.25);
    white-space: nowrap;
    display: inline-block;
    font-size: 12px;
    font-weight: normal;
    color: #fff;
    padding: 2px 8px;

    text-decoration: none;
    align-items: center;
    border-radius: 4px;
    border: none;
    background-color: $orange;
    text-align: center;

    &.large {
        font-size: 18px;
        font-weight: 600;
    }
    &:hover {
        color: #fff;
        background-color: $light-orange;
    }

    i {
        margin-right: 3px;
        @include media('<=sm') {
            display: none;
        }
    }

    @include media('<=md') {
        font-size: 10px;
    }
}
</style>
